
import { defineComponent, nextTick, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Customer } from '@/models/Order';
import router from '@/router';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import Q from 'q';

export default defineComponent({
    components: {},
    name: 'EditOrder',
    data() {
        return {};
    },
    async setup() {
        const route = useRoute();
        const id = route.params.id.toString();
        const customer = ref<Customer>();

        function close() {
            router.go(-1);
        }

        async function submitForm(event: any) {
            if (!event.target.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            swal.showLoading();
            await nextTick();
            const apiResult = await api.EditOrder(id, customer.value);
            await Q.delay(400);
            if (apiResult.hasValidationError) {
                const message = apiResult
                    .validationErrorsList()
                    ?.map(x => `Field '${x.key}': ${x.msg}`)
                    ?.join('\n');
                swal.fire({
                    icon: 'error',
                    text: message
                });
                return;
            }
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }

            close();
        }

        if (!id) {
            router.push({ name: 'orders' });
            return;
        }
        const customerStr = route.params.customer;
        if (!customerStr) {
            close();
            return;
        }
        customer.value = JSON.parse(customerStr.toString());

        return { close, submitForm, customer };
    }
});
